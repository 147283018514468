import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { generateAssetURLs } from '@saatva-bits/pattern-library.utils.product'
import { pushDataLayerEvent } from '@/utils/datalayer'

import ProductImage from '../ProductImage'

import ardadConfig from './image-config'

import styles from './Gallery.module.scss'

// Test EXP.LAYER_PDP_GALLERY.EX-172
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.hooks'
import productCodes from '@/temp-configs/product-codes'
//END EXP.LAYER_PDP_GALLERY.EX-172

const MobileGallery = ({
    images,
    thumbnails,
    selectionDeps,
    IMGIX_URL,
    productCode
}) => {
    const [selectedImageId, setSelectedImageId] = useState(images?.[0]?.id)

    const switchImage = (imageId) => {
        setSelectedImageId(imageId)
    }

    const mobileContainerClasses = classNames(styles.mobileContainer, 'u-hidden--lg-up')
    const selectedImageProps = images.find(image => image.id === selectedImageId)

    return <div className={mobileContainerClasses}>
        <ProductImage
            productCode={productCode}
            selectionDeps={selectionDeps}
            IMGIX_URL={IMGIX_URL}
            imageClasses={styles.featuredImage}
            containerClasses={styles.imageContainerMobile}
            {...selectedImageProps}
        />
        {/* Slider for thumbnails to choose */}
        <div className={styles.sliderContainer}>
            {thumbnails.map((thumbnail, i) => {
                const itemClasses = classNames(styles.sliderItem, {
                    [`${styles[`sliderItem-isActive`]}`]: thumbnail.id === selectedImageId
                })

                return <div
                    key={`${thumbnail.id}-thumbnail`}
                    className={itemClasses}
                    onClick={() => {
                        switchImage(thumbnail.id)
                    }}
                >
                    <ProductImage
                        {...thumbnail}
                        tag={''}
                        imageClasses={styles.sliderImage}
                        containerClasses={styles.sliderImageContainer}
                        productCode={productCode}
                        selectionDeps={selectionDeps}
                        IMGIX_URL={IMGIX_URL}
                        dataSelector={`gallery-thumbnail-${i}`}
                    />
                </div>
            }
            )}
        </div>
    </div>
}

const Gallery = ({
    IMGIX_URL,
    productCode,
    selectionDeps,
    productTag
}) => {
    const { useProductState } = selectionDeps
    const productState = useProductState(productCode, [])
    const desktopGalleryRef = useRef(null)
    
    useEffect(()=>{
        const thresholds = [50, 75]
        const desktopGallery = desktopGalleryRef.current
        
        const pushDataLayerOnScroll = () => {
            const scrollPercentage = (desktopGallery.scrollTop / (desktopGallery.scrollHeight - desktopGallery.clientHeight)) * 100
            if(scrollPercentage >= thresholds[0]){
                pushDataLayerEvent({
                    'event': 'galleryScroll',
                    'scrollThreshold': thresholds[0]
                })
                thresholds.shift()
            }
        }
        if(desktopGalleryRef) {
            desktopGallery.addEventListener('scroll', pushDataLayerOnScroll)
            
            return () => {
                desktopGallery.removeEventListener('scroll', pushDataLayerOnScroll)
            }
        }
    }, [])

    const desktopContainer = classNames(styles.desktopContainer, 'u-hidden--lg-down')

    // AB TEST EXP.LAYER_PDP_GALLERY.EX-172
    // Once test has finished replace assetsData for ardadConfig[productCode]
    // And delete all the code related to the test
    const { isV1: showLayerDiagram } = useExperiment('EXP.LAYER_PDP_GALLERY.EX-172')
    const hasLayerDiagramImg = showLayerDiagram && productCode === productCodes.classic

    const assetsData = ardadConfig[productCode]
    if (hasLayerDiagramImg) {
        assetsData.imageDescriptors = assetsData.imageDescriptors.filter(item => item !== 'lifestyle-bedroom')
    } else {
        assetsData.imageDescriptors = assetsData.imageDescriptors.filter(item => item !== 'layer-diagram')
    }
    // END Test

    const images = generateAssetURLs(productCode, assetsData, productState, '3-2').map((imageData, index) => {
        const tag = index === 0 ? productTag : ''
        return {
            id: `${productCode}-${index}`,
            alt: assetsData.altTag,
            tag,
            ...imageData
        }
    })

    const thumbnails = generateAssetURLs(productCode, assetsData, productState, '1-1').map((imageData, index) => {
        return {
            id: `${productCode}-${index}`,
            alt: assetsData.altTag,
            ...imageData
        }
    })

    return <>
        <div className={desktopContainer} ref={desktopGalleryRef}>
            {images.map((image) => (
                <ProductImage
                    key={`${image.id}-desktop`}
                    productCode={productCode}
                    selectionDeps={selectionDeps}
                    IMGIX_URL={IMGIX_URL}
                    imageClasses={styles.featuredImage}
                    containerClasses={styles.imageContainerDesktop}
                    {...image}
                />)
            )}
        </div>
        <MobileGallery
            productCode={productCode}
            selectionDeps={selectionDeps}
            IMGIX_URL={IMGIX_URL}
            images={images}
            thumbnails={thumbnails}
        />
    </>
}

Gallery.propTypes = {
    productCode: PropTypes.string.isRequired,
    selectionDeps: PropTypes.object.isRequired,
    IMGIX_URL: PropTypes.string.isRequired,
    productTag: PropTypes.string
}

export default Gallery